import React from 'react'

interface ImageProps {
  dir: string
  fileName: string
  className: string
  alt: string
  lazyLoading?: boolean
}

const Image = ({ dir, fileName, className, alt, lazyLoading }: ImageProps) => {
  return (
    <picture>
      <source srcSet={dir + fileName + '.webp'} type="image/webp" />
      <source srcSet={dir + fileName + '.png'} type="image/png" />
      <img
        className={className}
        alt={alt}
        loading={lazyLoading ? 'lazy' : 'eager'}
      />
    </picture>
  )
}

export default Image
