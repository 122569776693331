// TODO variable region, such as en-GB or fr-FR

import { CurrencyEnum } from '../../types/Currency'

export const formatCurrency = (
  num: number,
  digitAfterDecimalPoint = 0,
  divideByHundred: boolean = false,
  currency: CurrencyEnum,
  locale: string = 'en-US',
): string => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: digitAfterDecimalPoint,
  })

  return formatter.format(divideByHundred ? num / 100 : num)
}

export const formatNumber = (
  num: number,
  digitAfterDecimalPoint = 0,
): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: digitAfterDecimalPoint,
  })

  return formatter.format(num)
}

export const formatPercentage = (
  num: number,
  digitAfterDecimalPoint = 1,
  divideHundred: boolean,
): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: digitAfterDecimalPoint,
  })

  const fractionalNumber = num / 100

  return formatter.format(
    divideHundred ? fractionalNumber / 100 : fractionalNumber,
  )
}
