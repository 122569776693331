import React from 'react'

export default function WelcomeTitle() {
  return (
    <div className="block text-xl font-medium sm:hidden">
      <img
        className="mx-auto h-10 w-auto"
        src="/img/logos/shelters/logotype-colours-x500-min.png"
        alt="Shelters"
      />
    </div>
  )
}
