import React from 'react'
import Card from '../Card'
import Image from '../Image'
import Button, { buttonHierarchy, buttonShape } from '../Button'
import { useTranslation } from 'next-i18next'

export default function CtaCard() {
  const { t } = useTranslation('home')
  const listItems: string[] = t('ctaCard.list', { returnObjects: true })

  return (
    <Card
      className="group flex w-full space-x-0 border border-neutralGray/50 bg-neutralWhite p-3 lg:space-x-12"
      style={{ background: 'rgb(190 190 190 / 10%)' }}
    >
      <div className="relative hidden flex-auto items-center sm:flex">
        <Image
          dir="/img/icons/"
          fileName="phone-question-min"
          className="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 rotate-12 scale-[100%] transition group-hover:scale-[105%]"
          alt="phone 3D icon"
          lazyLoading={false}
        />
      </div>
      <div className="flex w-full flex-col text-center sm:w-4/5 sm:text-left">
        <div className="flex-grow">
          <h1 className="text-2xl font-medium text-neutralBlack lg:text-4xl">
            {t('ctaCard.title')}
          </h1>
          <p className="hidden pt-1 text-sm text-neutralBlack/80 sm:block">
            {t('ctaCard.subtitle')}
          </p>
          <ul className="py-1.5 pl-8 text-neutralBlack/80 sm:list-disc">
            {listItems &&
              listItems.map((item: any, index: number) => (
                <li className="py-1.5 text-sm sm:py-0.5" key={index}>
                  {item}
                </li>
              ))}
          </ul>
        </div>
        <div className="pt-1.5">
          <p className="text-xs font-bold text-neutralBlack/80">
            {t('ctaCard.readyCta')}
          </p>
          <Button
            hierarchy={buttonHierarchy.primary}
            shape={buttonShape.rectangle}
            link={process.env.NEXT_PUBLIC_CALENDLY}
            target="_blank"
            rel="noopener nofollow noreferrer external"
            className="text-md mt-1.5 inline-flex items-center rounded px-4 py-2 font-medium"
          >
            {t('ctaCard.exploreOriginals')}
          </Button>
        </div>
      </div>
    </Card>
  )
}
