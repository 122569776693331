import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { classNames } from '../../lib/utils/classNames'
import { HeroIcons } from '../../lib/utils/heroIcons'
import PropertyService from '../../services/property.service'
import { Property } from '../../types/Property/Property'
import { useTranslation } from 'next-i18next'
import routes from '../../config/routes'
import useLocale from '../../hooks/useLocale'
import PropertyCard from '../originals/PropertyCard'

export default function PropertyList() {
  const { t } = useTranslation('home')
  const { t: t2 } = useTranslation('common')
  const { locale } = useLocale()
  const [properties, setProperties] = useState<Property[]>([])

  const [activeTab, setActiveTab] = useState<string>(
    t('propertyListing.navigation.news'),
  )

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loadingError, setLoadingError] = useState<string | null>(null)
  const [fetchParams, setFetchParams] = useState<{
    page: number
    pageSize: number
    filter: string[]
    filterBy: string[]
    filterOperator: string[]
    filterCondition?: string
  }>({
    page: 1,
    pageSize: 7,
    filter: ['funding', 'not_started'],
    filterBy: ['fundingStatus', 'fundingStatus'],
    filterOperator: ['=', '='],
    filterCondition: 'or',
  })

  const tabs = [
    {
      name: t('propertyListing.navigation.news'),
    },
    {
      name: t('propertyListing.navigation.fundingComplete'),
    },
  ]

  const fetchProperties = async () => {
    setIsLoading(true)
    setLoadingError(null)

    try {
      const resProperties = await PropertyService.getProperties({
        ...fetchParams,
        lang: locale,
      })
      setProperties(resProperties.properties)
    } catch (e) {
      setLoadingError(t2('loading.error'))
    }
    setIsLoading(false)
  }

  const handleTabClick = (tabName: string) => {
    if (tabName === tabs[0].name) {
      setFetchParams({
        page: 1,
        pageSize: 7,
        filter: ['funding', 'not_started'],
        filterBy: ['fundingStatus', 'fundingStatus'],
        filterOperator: ['=', '='],
        filterCondition: 'or',
      })
    }

    if (tabName === tabs[1].name) {
      setFetchParams({
        page: 1,
        pageSize: 7,
        filter: ['funded'],
        filterBy: ['fundingStatus'],
        filterOperator: ['='],
      })
    }

    setActiveTab(tabName)
  }

  const displayProperties = (): React.JSX.Element => {
    if (isLoading) {
      return (
        <div className="flex h-full items-center py-24">
          <div className="mx-auto flex items-center">
            {t2('loading.inProgress')}
            <HeroIcons.ArrowPathIcon className="ml-2 h-4 w-4 animate-spin" />
          </div>
        </div>
      )
    }

    if ((!properties || !properties.length) && !loadingError) {
      return (
        <div className="flex h-full items-center py-24">
          <div className="mx-auto flex items-center text-center">
            {t2('loading.emptyResults')}
          </div>
        </div>
      )
    }

    if (loadingError) {
      return (
        <div className="flex h-full items-center py-24">
          <div className="mx-auto flex items-center text-center">
            {t2('loading.error')}
          </div>
        </div>
      )
    }

    return (
      <div className="grid-rows-auto grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
        {properties.map((property: Property) => (
          <PropertyCard key={property.id} property={property} />
        ))}
      </div>
    )
  }

  useEffect(() => {
    fetchProperties()
  }, [locale, fetchParams])

  return (
    <>
      <div className="flex border-b border-neutralGray bg-neutralWhite pb-2">
        <nav
          className="-mb-px flex space-x-1 text-sm md:space-x-8 md:text-lg"
          aria-label="Tabs"
        >
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => handleTabClick(tab.name)}
              type="button"
              className={classNames(
                'items-center px-1 font-medium hover:text-neutralBlack/50 md:px-0',
                tab.name === activeTab
                  ? 'text-neutralBlack '
                  : 'text-neutralGray',
              )}
            >
              <div>{tab.name}</div>
            </button>
          ))}
        </nav>
        <Link
          href={routes.properties}
          className="ml-auto flex items-center text-xs font-medium text-neutralBlack md:text-xl"
        >
          {t('propertyListing.navigation.seeMore')}
          <HeroIcons.ArrowRightIcon className="ml-1 h-3 w-auto md:h-5" />
        </Link>
      </div>
      <div className="flex flex-1 overflow-y-auto">
        <div className="w-full">{displayProperties()}</div>
      </div>
    </>
  )
}
