import React, { useMemo } from 'react'
import Card from '../Card'
import { useTranslation } from 'next-i18next'

type CardProps = {
  fundingStatus: 'funding' | 'funded' | 'paused' | 'not_started'
}

export default function Badge({ fundingStatus }: CardProps) {
  const { t } = useTranslation('common')

  const content = useMemo(() => {
    if (fundingStatus === 'funding') {
      return (
        <>
          <svg
            className="h-1.5 w-1.5 fill-green-500"
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx={3} cy={3} r={3} />
          </svg>
          {t('propertyCard.badges.funding.title')}
        </>
      )
    }

    if (fundingStatus === 'funded') {
      return (
        <>
          <svg
            className="h-1.5 w-1.5 fill-gray-500"
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx={3} cy={3} r={3} />
          </svg>
          {t('propertyCard.badges.funded.title')}
        </>
      )
    }
    if (fundingStatus === 'paused') {
      return (
        <>
          <svg
            className="h-1.5 w-1.5 fill-yellow-500"
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx={3} cy={3} r={3} />
          </svg>
          {t('propertyCard.badges.paused.title')}
        </>
      )
    }
    if (fundingStatus === 'not_started') {
      return (
        <>
          <svg
            className="h-1.5 w-1.5 fill-yellow-300"
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx={3} cy={3} r={3} />
          </svg>
          {t('propertyCard.badges.notStarted.title')}
        </>
      )
    }

    return <></>
  }, [fundingStatus, t])

  if (fundingStatus) {
    return (
      <Card className="relative inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-gray-200">
        {content}
      </Card>
    )
  }
  return <></>
}
